

import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables";
import {api_url} from "@/ayar";
import TableBaseComponent from "@/components/backoffice/table/TableBaseComponent.vue";
import {onMounted} from "vue";
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import { l }           from '@/core/helpers/lang';

export default ({
  name: "KariyerGostergesi",
  components: {
    TableBaseComponent
  },
  data: () => {
    return {
      url: api_url + 'kariyerGosterge',
      columns: [
        {
          title: l('admin:transfer:yil'),
          settings: {
            data: 'year',
            orderable: true
          }
        },
        {
          title: l('admin:transfer:ay'),
          settings: {
            data: 'month',
            orderable: true
          }
        },
        {
          title: l('admin:transfer:Kariyer'),
          settings: {
            data: 'careerName',
            orderable: true
          }
        },
        {
          title: l('web:transfer:Aktif Kariyer'),
          settings: {
            data: 'activeCareerName',
            orderable: true
          }
        }
      ],
      columnDefs: [
        {
          "render": function (data, type, row) {
            return "<div class='text-muted fw-bold text-muted d-block fs-7' >" + data + "</div>";
          },
          "targets": 0,
          className: 'text-right align-bottom'
        },
        {
          "render": function (data, type, row) {
            return "<div class='text-muted fw-bold text-muted d-block fs-7' >" + data + "</div>";

          },
          "targets": 1,
          className: 'text-left align-bottom'
        },
        {
          "render": function (data, type, row) {
            return "<div class='text-muted fw-bold text-muted d-block fs-7' >" + data + "</div>";
          },
          "targets": 2,
        },
        {
          "render": function (data, type, row) {
            return "<div class='text-muted fw-bold text-muted d-block fs-7' >" + data + "</div>";
          },
          "targets": 3,
        }
      ],

    }
  },
  methods: {
    drawCallback: function (obj) {
      document.querySelectorAll<HTMLElement>('.routerLink').forEach(element => {
        element.addEventListener("click", e => {
          e.preventDefault();
          obj.$router.push({name: element.dataset.name, params: {id: element.dataset.id}})
        });
      });
    }
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle(l('admin:transfer:Kariyer Göstergesi'));
    });
    return{l}
  }
});
